<template>
	<div class="row">
		<div class="col-12">
			<label>
				<b>{{ $t('employeeInfo.name') }}</b>
			</label>
			<InputText v-model="employeeInfo.firstName" :place="$t('employeeInfo.name')" :disabled="disabled" :required="true" />
		</div>
		<div class="col-12">
			<label>
				<b>{{ $t('employeeInfo.lastName') }}</b>
			</label>
			<InputText v-model="employeeInfo.lastName" :place="$t('employeeInfo.lastName')" :disabled="disabled" :required="true" />
		</div>
		<div class="col-12">
			<label>
				<b>{{ $t('employeeInfo.emailPrimary') }}</b>
			</label>
			<InputText v-model="employeeInfo.email" :place="$t('employeeInfo.emailPrimary')" :disabled="disabled" />
		</div>

		<div class="col-12">
			<label>
				<b>{{ $t('employeeInfo.emailSecondary') }}</b>
			</label>
			<InputText v-model="employeeInfo.emailSecondary" :place="$t('employeeInfo.emailSecondary')" :disabled="disabled" />
		</div>
		<!-- <b-form-input class="input col-12 mt-1" type="text" placeholder="Postnr."></b-form-input> -->
		<div class="col-12">
			<label>
				<b>{{ $t('employeeInfo.phone') }}</b>
			</label>
			<InputPhone v-model="employeeInfo.phone" :place="$t('employeeInfo.phone')" :disabled="disabled" />
		</div>
		<div class="col-12">
			<label
				><b>{{ $t('employeeInfo.cpr') }}</b></label
			>
			<InputText v-model="employeeInfo.cpr" :place="$t('employeeInfo.cpr')" :disabled="disabled" :required="true" />
		</div>
	</div>
</template>

<script>
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputPhone from '@/modules/global/atomComponents/InputPhone.vue'

export default {
	name: 'EmployeeInfo',
	mounted() {
		console.log('EMPLOYEE INFO LOADED')
	},
	props: {
		value: Object,
		disabled: Boolean,
	},
	components: {
		InputText,
		InputPhone
	},
	computed: {
		employeeInfo: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
}
</script>

<style></style>
